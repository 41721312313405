const colors = {
  background: {
    one: "#030309",
    two: "#121218",
    three: "#1C1C22",
    four: "#26262B",
  },
  borderDark: "#1F1F1F",
  borderDark2: "#0D0D13",
  border: "#1C1C22",
  borderLight: "#2E2C2B",
  text: "#ACACAB",
  primaryText: "#FFFFFF",
  secondaryText: "#A1A1A9",
  tertiaryText: "#787883",
  quaternaryText: "#3F3F44",
  green: "#16CEB9",
  red: "#fc0a54",
  pillBackground: "#151413",
  tagBackground: "#1D222D",
  products: {
    yield: "#FF385C",
    volatility: "#FF9000",
    principalProtection: "#79FFCB",
    capitalAccumulation: "#729DED",
  },
  buttons: {
    primary: "#fc0a54",
    error: "rgba(252, 10, 84, 0.16)",
    secondaryBackground: "rgba(22, 206, 185, 0.08)",
    secondaryBackground2: "rgba(22, 206, 185, 0.12)",
    secondaryText: "#16ceb9",
  },
  vaultActivity: {
    sales: "#16ceb9",
    minting: "#729ded",
    openLoan: "#729ded",
    closeLoan: "#729ded",
    optionSold: "#16ceb9",
    optionYield: "#729ded",
  },
  brands: {
    opyn: "#6FC0A2",
    hegic: "#45FFF4",
    charm: "#5A67D8",
    discord: "#5A65EA",
    primitive: "#FFFFFF",
  },
  asset: {
    WETH: "#627EEA",
    WBTC: "#E39652",
    USDC: "#3E73C4",
    "USDC.e": "#3E73C4",
    yvUSDC: "#3E73C4",
    stETH: "#00A3FF",
    wstETH: "#00A3FF",
    AAVE: "#2EBAC6",
    UNI: "#FF007A",
    WBNB: "#F0B90B",
    WAVAX: "#E84142",
    sAVAX: "#11BAF0",
    rETH: "#EF4444",
    PERP: "#00BC9A",
    BAL: "#FFFFFF",
    BADGER: "#F1A23F",
    SPELL: "#5552FD",
    SAMB: "#457EFF",
    RBN: "#fc0a54",
    veRBN: "#fc0a54",
    LDO: "#00A3FF",
    SOL: "#14F195",
    APE: "#0054F9",
  },
  wallets: {
    Metamask: "#F89D35",
    WalletConnect: "#5697F5",
    WalletLink: "#1C55E7",
    Phantom: "#5442C0",
    Solflare: "#FC5728",
  },
};

export default colors;
