/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  VotingEscrowDelegationProxy,
  VotingEscrowDelegationProxyInterface,
} from "../VotingEscrowDelegationProxy";

const _abi = [
  {
    name: "CommitAdmins",
    inputs: [
      {
        type: "address",
        name: "ownership_admin",
        indexed: false,
      },
      {
        type: "address",
        name: "emergency_admin",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "ApplyAdmins",
    inputs: [
      {
        type: "address",
        name: "ownership_admin",
        indexed: false,
      },
      {
        type: "address",
        name: "emergency_admin",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    name: "DelegationSet",
    inputs: [
      {
        type: "address",
        name: "delegation",
        indexed: false,
      },
    ],
    anonymous: false,
    type: "event",
  },
  {
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "_delegation",
      },
      {
        type: "address",
        name: "_voting_escrow",
      },
      {
        type: "address",
        name: "_o_admin",
      },
      {
        type: "address",
        name: "_e_admin",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    name: "adjusted_balance_of",
    outputs: [
      {
        type: "uint256",
        name: "",
      },
    ],
    inputs: [
      {
        type: "address",
        name: "_account",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "kill_delegation",
    outputs: [],
    inputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "set_delegation",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "_delegation",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "commit_set_admins",
    outputs: [],
    inputs: [
      {
        type: "address",
        name: "_o_admin",
      },
      {
        type: "address",
        name: "_e_admin",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "apply_set_admins",
    outputs: [],
    inputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    name: "delegation",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "voting_escrow",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "emergency_admin",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "ownership_admin",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "future_emergency_admin",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    name: "future_ownership_admin",
    outputs: [
      {
        type: "address",
        name: "",
      },
    ],
    inputs: [],
    stateMutability: "view",
    type: "function",
  },
];

export class VotingEscrowDelegationProxy__factory {
  static readonly abi = _abi;
  static createInterface(): VotingEscrowDelegationProxyInterface {
    return new utils.Interface(_abi) as VotingEscrowDelegationProxyInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): VotingEscrowDelegationProxy {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as VotingEscrowDelegationProxy;
  }
}
