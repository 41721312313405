import { SVGProps } from "./shared";

export const Badger: React.FC<SVGProps> = ({ ...props }) => (
  <svg
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_605_110)">
      <rect width="56" height="56" rx="28" fill="#F1A23F" fillOpacity="0.12" />
      <path
        d="M13.5743 26.0211H14.9342V20.5514H13.5743V19.2168H12.1581V20.5535H13.5743V26.0211Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M16.3672 26.0206H14.9361V28.7613H16.3672V26.0206Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M19.0813 36.9582V38.2915H20.5006V35.5638H19.0833V31.4862H17.7269V28.7624H16.3674V31.4887H17.7253V35.5646H16.3669V36.9568V38.2915H16.3674V36.9582H19.0813Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M16.3643 34.1571H14.9317V32.8232H13.5756V34.1595H14.9317V35.5647H16.3643V34.1571Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M24.7075 15.1584V12.4047H24.7067V11.0227H23.289V11.0219V9.68719H21.8616H20.4995V11.0227H19.0811V12.4155H16.3651V9.68738H13.575L13.576 11.0217H13.575V11.022H12.1603V12.4138H12.1573V12.415H10.8019V15.157H12.1587V18.4411V19.1667V19.2162H13.5749V20.5509H14.9346V26.0206H16.3657V28.7613V28.7628H17.725V31.4866H19.0815V35.5643H20.4986V38.2918H19.0793V36.9587H16.3657V38.2918H17.724V39.2359V39.6848H19.0809V40.9588H20.4986V42.3671H21.8562H23.2879V40.9581H24.5843H24.7061V35.5643H24.7069H26.0644V28.7618H24.7069V28.7597H24.7061V17.8248H24.7069H26.0637H26.0644V15.1658V15.1585H24.7075V15.1584ZM21.8616 31.4902H21.043H20.4987V30.1439H21.043H21.8616V31.4902Z"
        fill="#F1A23F"
        fillOpacity="0.12"
      />
      <path
        d="M16.3644 35.565H14.9319V36.9573H16.3644V35.565Z"
        fill="#F1A23F"
        fillOpacity="0.24"
      />
      <path
        d="M12.1603 11.0225V11.0222H10.8019V12.4143H12.1572V11.0225H12.1603Z"
        fill="#F1A23F"
        fillOpacity="0.24"
      />
      <path
        d="M12.161 11.0222H12.1581V12.414H12.161V11.0222Z"
        fill="#F1A23F"
        fillOpacity="0.12"
      />
      <path
        d="M13.577 9.68754H16.3673V12.4156H19.0833V11.0228H20.5015V9.68754H17.7076V9.21731V8.3586H13.578H12.1625V11.0218H13.577V9.68754Z"
        fill="#F1A23F"
      />
      <path
        d="M13.5772 34.1593H13.5756V35.5646H13.5772V34.1593Z"
        fill="#F1A23F"
        fillOpacity="0.24"
      />
      <path
        d="M14.9317 34.1593H13.5756V35.5646H14.9317V34.1593Z"
        fill="#F1A23F"
        fillOpacity="0.24"
      />
      <path
        d="M16.3678 31.4887V28.7625V28.7612H14.9369V26.0205H13.577V20.5529H12.1609H10.804V21.9618H9.44562V30.1562H10.804V32.8231H12.1585V34.1593H13.144H13.577H13.5785V32.8231H14.9346V34.157H16.3673V35.5647H17.7256V31.4887H16.3678Z"
        fill="#F1A23F"
      />
      <path
        d="M24.7075 40.9577H24.5857H23.2891V42.3666H21.8575V43.7583H23.2899H24.7083V42.3666V40.9585V40.9577H24.7075Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M24.71 40.9586H26.0676V35.5641H24.71V40.9579V40.9586Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M26.0668 17.8249H24.71V28.7598H26.0668V17.8249Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M24.7064 17.8249H24.7056V28.7598H24.7064V17.8249Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M27.4238 15.1656H26.0661V17.8246H27.4238V15.1656Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M26.0633 11.0222H24.7056V11.023H24.7064V12.405H26.0633V11.0222Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M24.7064 11.0233H24.7056V12.4053H24.7064V11.0233Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M24.7058 9.68719H23.2881V11.0219H24.7058V9.68719Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M23.2899 43.7586H21.8575V46.4862H23.2899V43.7586Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M24.7066 46.4865H23.2881V47.835H24.7066V46.4865Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M26.0654 47.8344H24.71V49.2265H26.0654V47.8344Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M21.0453 30.1446H20.5012V31.4909H21.0453H21.8639V30.1446H21.0453Z"
        fill="#F1A23F"
      />
      <path
        d="M31.6147 11.0217V9.68719H28.1602H24.7056V11.0217H26.0633V12.4044H24.7064V15.1582H26.0641V15.1655H27.4216V17.8247H26.0641H26.0633V28.7595H24.7064V28.7616H26.0641V35.5638V40.9584H24.7064V42.3666H28.1602H31.6139V40.9584H30.2562V35.5638V28.7616H31.6139V28.7595H30.257V17.8247H30.2562H28.8987V15.1655H30.2562V15.1582H31.6139V12.4044H30.257V11.0217H31.6147Z"
        fill="#F1A23F"
      />
      <path
        d="M38.5978 35.5646V31.4887H39.9555V28.7624H38.5962V31.4862H37.2398V35.5638H35.8225V38.2915H37.2418V36.9582H39.9555V38.2915H39.9562V36.9568V35.5646H38.5978Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M42.7455 34.1595V32.8232H41.3894V34.1571H39.9568V35.5647H41.3894V34.1595H42.7455Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M42.7474 20.5514H41.3875V26.0211H42.7474V20.5535H44.1635V19.2168H42.7474V20.5514Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M41.388 26.0206H39.9568V28.7613H41.388V26.0206Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M44.1654 12.4148V12.4137H44.1625V11.0219H42.7479V11.0215H42.7469L42.7479 9.68719H39.9577V12.4155H37.2416V11.0227H35.8231V9.68719H34.461H33.0337V11.0219V11.0227H31.616V12.4047H31.6152V15.1584H30.2575V15.1657V17.8247H30.2583H31.6152H31.616V28.7595H31.6152V28.7617H30.2575V35.5638H31.6152H31.616V40.9577H31.7378H33.0342V42.3666H34.4659H35.8235V40.9585H37.2412V39.6843H38.598V39.2356V38.2915H39.9564V36.9584H37.2427V38.2915H35.8235V35.5638H37.2406V31.4862H38.597V28.7625H39.9564V28.7609V26.0201H41.3875V20.5505H42.7474V19.2158H44.1635V19.1664V18.4408V15.1567H45.5204V12.4148H44.1654ZM35.8239 31.4902H35.2796H34.461V30.1439H35.2796H35.8239V31.4902Z"
        fill="#F1A23F"
        fillOpacity="0.12"
      />
      <path
        d="M41.3893 35.565H39.9568V36.9573H41.3893V35.565Z"
        fill="#F1A23F"
        fillOpacity="0.24"
      />
      <path
        d="M44.1643 11.0225V12.4143H45.5196V11.0222H44.1613V11.0225H44.1643Z"
        fill="#F1A23F"
        fillOpacity="0.24"
      />
      <path
        d="M37.2408 11.0228V12.4156H39.9568V9.68754H42.747V11.0218H44.1616V8.3586H42.746H38.6165V9.21731V9.68754H35.8225V11.0228H37.2408Z"
        fill="#F1A23F"
      />
      <path
        d="M42.7496 34.1593H42.7481V35.5646H42.7496V34.1593Z"
        fill="#F1A23F"
        fillOpacity="0.24"
      />
      <path
        d="M42.7478 34.1593H41.3918V35.5646H42.7478V34.1593Z"
        fill="#F1A23F"
        fillOpacity="0.24"
      />
      <path
        d="M45.5222 21.9618V20.5529H44.1654H42.7494V26.0205H41.3893V28.7612H39.9584V28.7625V31.4887H38.6006V35.5647H39.9591V34.157H41.3917V32.8231H42.7478V34.1593H42.7494H43.1822H44.1677V32.8231H45.5222V30.1562H46.8806V21.9618H45.5222Z"
        fill="#F1A23F"
      />
      <path
        d="M33.0374 42.3666V40.9577H31.7409H31.6189H31.6181V40.9585V42.3666V43.7583H33.0366H34.4691V42.3666H33.0374Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M31.6151 35.5641H30.2575V40.9586H31.6151V40.9579V35.5641Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M31.6144 17.8249H30.2575V28.7598H31.6144V17.8249Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M31.6189 17.8249H31.6181V28.7598H31.6189V17.8249Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M30.2589 15.1656H28.9012V17.8246H30.2589V15.1656Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M31.6152 11.023V11.0222H30.2575V12.405H31.6144V11.023H31.6152Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M31.6189 11.0233H31.6181V12.4053H31.6189V11.0233Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M33.0358 9.68719H31.6181V11.0219H33.0358V9.68719Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M34.4681 43.7586H33.0356V46.4862H34.4681V43.7586Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M33.0366 46.4865H31.6181V47.835H33.0366V46.4865Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M31.6173 47.8344H30.2619V49.2265H31.6173V47.8344Z"
        fill="#F1A23F"
        fillOpacity="0.64"
      />
      <path
        d="M31.614 43.7583V42.3667H28.1603H24.7066V43.7583H23.2881V46.4858H24.7066V47.8343H26.0618V49.2264H28.1603H30.2587V47.8343H31.614V46.4858H33.0324V43.7583H31.614Z"
        fill="#F1A23F"
        fillOpacity="0.12"
      />
      <path
        d="M35.2803 30.1446H34.4617V31.4909H35.2803H35.8244V30.1446H35.2803Z"
        fill="#F1A23F"
      />
    </g>
    <defs>
      <clipPath id="clip0_605_110">
        <rect width="56" height="56" rx="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
